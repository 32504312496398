let TypeMaster = [
  {
    typeNum: 1,
    typeName: "Food Safety",
    standard: [
      {
        standardNum: 1,
        standardName: "CODEX GHPs v 2020",
      },
      {
        standardNum: 2,
        standardName: "HACCP v 2020",
      },
      {
        standardNum: 3,
        standardName: "มกษ. 9023",
      },
      {
        standardNum: 4,
        standardName: "มกษ. 9039",
      },
      {
        standardNum: 5,
        standardName: "มกษ. 9046",
      },
      {
        standardNum: 6,
        standardName: "มกษ. 9024",
      },
      {
        standardNum: 7,
        standardName: "มกษ. 9041",
      },
      {
        standardNum: 8,
        standardName: "มกษ. 9047",
      },
      {
        standardNum: 9,
        standardName: "มกษ. 9035",
      },
      {
        standardNum: 10,
        standardName: "มกษ. 4403",
      },
    ],
  },
  {
    typeNum: 2,
    typeName: "ISO",
    company: [
      {
        companyNum: 1,
        companyName: "UIC",
        standard: [
          {
            companyNum: 1,
            standardNum: 11,
            standardName: "ISO9001 : 2015",
          },
          {
            companyNum: 1,
            standardNum: 12,
            standardName: "ISO14001 : 2015",
          },
          {
            companyNum: 1,
            standardNum: 13,
            standardName: "ISO45001 : 2018",
          },
          {
            companyNum: 1,
            standardNum: 14,
            standardName: "ISO13485 : 2016",
          },
          {
            companyNum: 1,
            standardNum: 15,
            standardName: "ISO27001 : 2013",
          },
          {
            companyNum: 1,
            standardNum: 16,
            standardName: "ISO39001 : 2012",
          },
          {
            companyNum: 1,
            standardNum: 17,
            standardName: "ISO22000 : 2018",
          },
          {
            companyNum: 1,
            standardNum: 99,
            standardName: "Other",
          },
        ],
      },
      {
        companyNum: 2,
        companyName: "IGC",
        standard: [
          {
            companyNum: 2,
            standardNum: 11,
            standardName: "ISO9001 : 2015",
          },
          {
            companyNum: 2,
            standardNum: 12,
            standardName: "ISO14001 : 2015",
          },
          {
            companyNum: 2,
            standardNum: 13,
            standardName: "ISO45001 : 2018",
          },
          {
            companyNum: 2,
            standardNum: 14,
            standardName: "ISO13485 : 2016",
          },
          {
            companyNum: 2,
            standardNum: 15,
            standardName: "ISO27001 : 2013",
          },
          {
            companyNum: 2,
            standardNum: 16,
            standardName: "ISO39001 : 2012",
          },
          {
            companyNum: 2,
            standardNum: 17,
            standardName: "ISO22000 : 2018",
          },
          {
            companyNum: 2,
            standardNum: 18,
            standardName: "ISO50001 : 2011",
          },
          {
            companyNum: 2,
            standardNum: 19,
            standardName: "ISO22301 : 2019",
          },
          {
            companyNum: 2,
            standardNum: 20,
            standardName: "ISO/IEC27701 : 2019",
          },
          {
            companyNum: 2,
            standardNum: 21,
            standardName: "FSSC22000",
          },
          {
            companyNum: 2,
            standardNum: 99,
            standardName: "Other",
          },
        ],
      },
    ],
  },
];

export default TypeMaster;
