// @ts-nocheck
import Vue from 'vue'
Vue.mixin( {
    computed: {
        user ()
        {
            return  this.$store.state.user
        },
        token ()
        {
            return this.$store.state.token
        },
    }
})