import { HTTP } from "@/service/axios";
export default {
  methods: {
    checkAuth() {
      this.$confirm("กรุณาเข้าสู่ระบบใหม่อีกครั้ง", {
        confirmButtonText: "OK",
        showCancelButton: false,
        type: "error",
      }).then(() => {
        setTimeout(() => {
          window.localStorage.eram.clear();
        }, 200);
        this.$store.commit("SIGNOUT");
        window.localStorage.removeItem("UIC");
        delete HTTP.defaults.headers.common.Authorization;
        delete HTTP.defaults.headers.common['X-CSRF-TOKEN'];
        this.$router.push("/");
        setTimeout(() => {
          this.$router.go();
        }, 250);
      });
    },
  },
};
