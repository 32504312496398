import option from "./constant/Option";
import imgLogoDoc from "./constant/imgLogoDoc";
import typeMaster from "./constant/TypeMaster";
import calendarStatus from "./constant/calendarStatus";

const initState = () => ({
  _token: null,
  user: [],
  setCsrf: null,
  optionAll: option,
  imgLogoDoc: imgLogoDoc,
  typeMaster: typeMaster,
  showHideCertification: false,
  calendarStatus: calendarStatus,
  typeOfVisit: [
    {
      value: 1,
      label: "Off-site",
    },
    {
      value: 2,
      label: "On-site",
    },
    {
      value: 3,
      label: "Remote",
    },
  ],
});
export default initState;
