import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import mutation from "./mutation";
import initState from "./initState";
Vue.use(Vuex);

const plugins = [
  createPersistedState({
    key: "UIC",
  }),
];
export default new Vuex.Store({
  state: initState,
  mutations: mutation,
  actions: {},
  modules: {},
  plugins,
});
