<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import "./mixin/Auth";
export default {
  name: "app",
};
</script>

