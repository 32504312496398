import Vue from "vue";
import jwt from "jsonwebtoken";

import moment from "moment";
import momentTH from "moment/src/locale/th";
import { HTTP } from "@/service/axios";

Vue.mixin({
  methods: {
    getTokenCsrf(token){
      HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
      HTTP.get(`user/getcsrftoken`).then((res) => {
        if (res.data.success) {
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = res.data.csrfToken;
          this.$store.commit("SET_CSRF", res.data.csrfToken);
          let csrfTokenMeta = document.querySelector(
            'meta[name="csrf-token"]'
          );
          csrfTokenMeta.setAttribute("content",res.data.csrfToken);
        }
      });
    },
    isNumberDot: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 45 || charCode > 57 || charCode == 47) &&
        charCode != 189
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mapDataOption(value, option, type) {
      let result = "";
      let findData = null;
      if (type == "standard") {
        findData = option.find((row) => row.standardNum == value);
        if (findData != undefined && findData != null) {
          result = findData.standardName;
        }
      } else if (type == "company") {
        findData = option.find((row) => row.companyNum == value);
        if (findData != undefined && findData != null) {
          result = findData.companyName;
        }
      } else {
        findData = option.find((row) => row.value == value);
        if (findData != undefined && findData != null) {
          result = findData.label;
        }
      }

      return result;
    },
    setStandardView(
      typeCheck,
      value,
      optionsStandardFood,
      optionsStandardIsoOne,
      optionsStandardIsoTwo
    ) {
      let result = "";
      let setArray = [];

      if (value != undefined) {
        value.forEach((item) => {
          let findSome = setArray.some(
            (std) => std.standard == item.companyType.standard
          );
          if (!findSome) {
            setArray.push({
              standard: item.companyType.standard,
              scoped: item.companyType.scoped,
            });

            result += `<span>`;
            if (typeCheck == "standard") {
              result +=
                item.companyType.type == 1
                  ? this.mapDataOption(
                      item.companyType.standard,
                      optionsStandardFood,
                      "standard"
                    )
                  : this.mapDataOption(
                      item.companyType.standard,
                      item.companyType.company == 1
                        ? optionsStandardIsoOne
                        : optionsStandardIsoTwo,
                      "standard"
                    );
              result +=
                item.companyType.standard == 99
                  ? `(${item.companyType.other})`
                  : "";
            } else if (typeCheck == "scope") {
              result += item.companyType.scoped;
            }

            result += `</span>`;
          }
        });
      }

      return result;
    },
    returnAddress(value) {
      let text = value.replaceAll(" ", "&nbsp;");
      return text;
    },
    ignoreKey: function (evt) {
      evt = evt ? evt : window.event;
      evt.preventDefault();
    },
    formatDate(date) {
      return moment(
        moment(date).locale("th", momentTH).add(543, "year")
      ).format("LL");
    },
  },
});
