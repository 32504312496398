import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5 * 1000 * 60,
});

export const HTTPTYPELIVE = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_LIVE_DEV,
  timeout: 5 * 1000 * 60,
});

export const HTTPLIVE = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_LIVE_API,
  timeout: 5 * 1000 * 60,
});
