import Vue from "vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";

import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueQuillEditor from "vue-quill-editor";
import Quill from "quill";
import VueNumeric from "vue-numeric";

import moment from "moment";
import momentTH from "moment/src/locale/th";
import VueCsrf from 'vue-csrf';

Vue.use(VueNumeric);

Vue.use(VueCsrf);

import VueCookies from "vue-cookies";
Vue.use(VueCookies, { expire: "7d" });
// import "@/service/alert";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.bubble.css"; // for bubble theme
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import AutoLinks from "quill-auto-links";
import "quill/dist/quill.snow.css";
import Auth from "@/service/Auth";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
const quillTable = require("quill-table");
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/table", quillTable.TableModule);
Quill.register("modules/autoLinks", AutoLinks);
Vue.use(VueQuillEditor /* { default global options } */);

Vue.config.productionTip = false;
Vue.mixin(Auth);
Vue.filter("formatDateTH", function (value) {
  if (value) {
    return moment(moment(value).locale("th", momentTH).add(543, "year")).format(
      "LL"
    );
  }
});

Vue.filter("formatDateEN", function (value) {
  if (value) {
    return moment(moment(value)).format("D MMM YYYY");
  }
});

Vue.filter("formatDateTHFullTime", function (value) {
  if (value) {
    return (
      moment(moment(value).locale("th", momentTH).add(543, "year")).format(
        "lll"
      ) + " น."
    );
  }
});

Vue.filter("toFiexd", function (value) {
  if (value) {
    let fiexd = value.toFixed(2);
    return Number(fiexd);
  }
});

import "@/service/customFunction";
import "@/style/index.scss";

Vue.use(ElementUI, {
  locale,
});
new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
