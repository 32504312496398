// @ts-nocheck
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { HTTP } from "@/service/axios";

const ifCheckToken = (to, from, next) => {
  if (
    typeof store.state._token === "undefined" ||
    store.state._token === null ||
    store.state._token === ""
  ) {
    next("/");
  } else {
    if (store.state.setCsrf) {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = store.state.setCsrf;
    }

    next();
  }
};

const ifCheckTokenAdmin = (to, from, next) => {
  if (
    typeof store.state._token === "undefined" ||
    store.state._token === null ||
    store.state._token === ""
  ) {
    next("/");
  } else {
    if (store.state.user.role != 50) {
      if (store.state.setCsrf) {
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = store.state.setCsrf;
      }
      next();
    } else {
      router.go(-1);
    }
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    beforeEnter: ifCheckToken,
    // component: () => import("@/views/WebMaintenance"),
    component: () => import("@/views/ErrorScreen"),
  },
  {
    path: "/not-found-page",
    component: () => import("@/views/ErrorScreen"),
  },
  {
    path: "/maintenance",
    component: () => import("@/views/WebMaintenance"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Login"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword"),
  },
  {
    path: "/approval-result",
    name: "ApprovalResult",
    component: () => import("@/views/ApprovalResult"),
  },
  {
    path: "/create/user/role",
    name: "CreateUserCustom",
    component: () => import("@/views/CreateUserCustom"),
  },
  {
    path: "/profile",
    name: "EditProfile",
    component: () => import("@/views/EditProfile"),
    beforeEnter: ifCheckToken,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("@/views/ChangePassword"),
    beforeEnter: ifCheckToken,
  },

  {
    path: "/master-data/clients",
    name: "MasterDataClients",
    component: () => import("@/views/MasterDataClients"),
    meta: {
      active: "master-data",
      typePage: "clients",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/master-data/:typePage",
    name: "MasterData",
    component: () => import("@/views/MasterData"),
    meta: {
      active: "master-data",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/certification-requisition",
    name: "Requisition",
    component: () => import("@/views/Requisition"),
    meta: {
      active: "requisition",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/certification-requisition/add",
    name: "RequisitionAdd",
    component: () => import("@/views/RequisitionAdd"),
    meta: {
      active: "requisition",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/certification-requisition/edit/:id",
    name: "RequisitionEdit",
    component: () => import("@/views/RequisitionEdit"),
    meta: {
      active: "requisition",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/matching",
    name: "Matching",
    component: () => import("@/views/Matching"),
    meta: {
      active: "matching",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/matching/assessment-plan",
    name: "AssessmentPlan",
    component: () => import("@/views/AssessmentPlan"),
    meta: {
      typePage: "assessment",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/matching/assessment-plan/add",
    name: "AssessmentPlanAdd",
    component: () => import("@/views/AssessmentPlanAdd"),
    meta: {
      typePage: "assessment",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/matching/assessment-plan/edit/:id",
    name: "AssessmentPlanEdit",
    component: () => import("@/views/AssessmentPlanEdit"),
    meta: {
      typePage: "assessment",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/matching/assessment-plan/detail/:id",
    name: "AssessmentPlanView",
    component: () => import("@/views/AssessmentPlanView"),
    meta: {
      typePage: "assessment",
    },
    beforeEnter: ifCheckToken,
  },

  {
    path: "/certification",
    name: "Certification",
    component: () => import("@/views/Certification"),
    meta: {
      active: "certification",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/certification/add",
    name: "CertificationAdd",
    component: () => import("@/views/CertificationAdd"),
    meta: {
      active: "certification",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/certification/view/:id",
    name: "CertificationView",
    component: () => import("@/views/CertificationView"),
    meta: {
      active: "certification",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/certification/edit/:id",
    name: "CertificationEdit",
    component: () => import("@/views/CertificationEdit"),
    meta: {
      active: "certification",
    },
    beforeEnter: ifCheckTokenAdmin,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("@/views/Calendar"),
    meta: {
      active: "calendar",
    },
    beforeEnter: ifCheckToken,
  },
  {
    path: "/calendar/auto/:id",
    name: "CalendarAuto",
    component: () => import("@/views/CalendarAuto"),
    meta: {
      active: "calendar",
    },
    beforeEnter: ifCheckToken,
  },
];
///create/user/role
const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_SUBFOLDER,
  routes,
});

export default router;
