import initState from "./initState";
import { HTTP } from "@/service/axios";

export default {
  SIGNOUT: (state) => {
    state.user = null;
    window.localStorage.removeItem("UIC");
    delete HTTP.defaults.headers.common.Authorization;
    delete HTTP.defaults.headers.common['X-CSRF-TOKEN'];
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_TOKEN: (state, payload) => {
    state._token = payload;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
  SET_CSRF: (state, payload) => {
    state.setCsrf = payload;
  },
  SET_SHOW_CERTIFICATION_HIDE: (state, payload) => {
    state.showHideCertification = payload;
  },
};
