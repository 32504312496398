export default {
  statusChecking: [
    {
      value: 1,
      label: "ตรวจ State 1",
    },
    {
      value: 2,
      label: "ตรวจ State 2",
    },
    {
      value: 3,
      label: "SUR 1",
    },
    {
      value: 4,
      label: "SUR 2",
    },
    {
      value: 5,
      label: "SUR 3",
    },
    {
      value: 6,
      label: "SUR 4",
    },
    {
      value: 7,
      label: "SUR 5",
    },
    {
      value: 8,
      label: "Recer",
    },
    {
      value: 9,
      label: "SP 1",
    },
  ],
  customerStatus: [
    {
      value: 1,
      label: "เปิดใบสมัคร",
    },
    {
      value: 2,
      label: "ตรวจ State 1",
    },
    {
      value: 3,
      label: "ตรวจ State 2",
    },
    {
      value: 4,
      label: "CAR",
    },
    {
      value: 5,
      label: "ออกใบ Comfirm",
    },
    {
      value: 6,
      label: "รอตรวจรายงาน",
    },
    {
      value: 7,
      label: "ทบทวนชุดรายงาน",
    },
    {
      value: 8,
      label: "ตัดสินการรับรอง",
    },
    {
      value: 9,
      label: "ออกใบ Certificate",
    },
    {
      value: 10,
      label: "SUR 1",
    },
    {
      value: 11,
      label: "SUR 2",
    },
    {
      value: 12,
      label: "SUR 3",
    },
    {
      value: 13,
      label: "SUR 4",
    },
    {
      value: 14,
      label: "SUR 5",
    },
    {
      value: 15,
      label: "Recer",
    },
    {
      value: 16,
      label: "SP 1",
    },
  ],
};
