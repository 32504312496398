import typeMaster from "./TypeMaster";

let findStandardFood = typeMaster.find((f) => f.typeNum == 1);
let findCompanyIso = typeMaster.find((f) => f.typeNum == 2);
let findStandardIsoOne = [];
let findStandardIsoTwo = [];

findCompanyIso.company.forEach((row) => {
  if (row.companyNum == 1) {
    findStandardIsoOne = row.standard;
  } else {
    findStandardIsoTwo = row.standard;
  }
});

export default {
  optionsStandardFood: findStandardFood.standard,
  optionsCompanyIso: findCompanyIso.company,
  optionsStandardIsoOne: findStandardIsoOne,
  optionsStandardIsoTwo: findStandardIsoTwo,
  auditCycle: [
    {
      value: 6,
      label: "6 เดือน",
    },
    {
      value: 12,
      label: "12 เดือน",
    },
  ],
  status: [
    {
      value: 1,
      label: "รออนุมัติ",
    },
    {
      value: 2,
      label: "ไม่อนุมัติ",
    },
    {
      value: 3,
      label: "อนุมัติ",
    },
  ],
  accrecditFood: [
    {
      value: 1,
      label: "Accredit By ACFS",
    },
    {
      value: 4,
      label: "none Accredit",
    },
  ],
  accrecditISO: [
    {
      value: 2,
      label: "Accredit By UKAS",
    },
    {
      value: 3,
      label: "Accredit By IAS",
    },
    {
      value: 4,
      label: "none Accredit",
    },
  ],
};
